import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { fetchDataGen } from "./addit/Fetchs.jsx";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const CardProduct = (props) => {
  const [images, setDataImg] = useState([]);
  const [productos, setData] = useState([]);
  const [PriceProduct, setDataPrice] = useState([]);
  const [idProduct, setID] = useState([]);
  const [Discount, setDiscount] = useState([]);
  const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

  useEffect(() => {
    // const encodedProps = props.Family.map(key => 'filters[product_familie][id]$eq]=' + key
    // ).join('&');
    const encodedProps = props.Family.map(key => 'filters[product_family][id]$eq]=' + key
    ).join('&');

    // const encodedPropsPrice = props.Family.map(key => 'filters[product_family][id]$eq]=' + key
    // ).join('&');

    const encodedPropsPrice = props.Family.map(key => 'filters[product_family][id]$eq]=' + key
    ).join('&');

    fetchDataGen(props, encodedProps).then(data => {
      const filteredProdCatalog = data.map((Products) => {
        const filteredProducts = Products.attributes.productImages.data
          ? Products.attributes.productImages.data.filter((Images) => Images !== null)
          : null;

        //const filteredDiscount = Products.attributes.discount.data
        const filteredDiscount = Products.attributes.discount

        return { ...Products, Images: filteredProducts, discount: filteredDiscount };

      }).filter((Products) => Products.Images !== null);


      const idProducts = filteredProdCatalog.map(id => id.id);
      const images = filteredProdCatalog.map(img => img.Images);
      const discount = filteredProdCatalog.map(desc => desc.discount);
      const DataProd = filteredProdCatalog.map(item => item.attributes);
      //const Price = DataProd.map(price => price.price_catalogue);
      const Price = DataProd.map(price => price.price);

      setDiscount(discount);
      setID(idProducts);
      setDataPrice(Price);
      setDataImg(images);
      setData(DataProd);
      props.CantProducts(DataProd.length);
    })
      .catch(error => {
        console.error(error);

      });
  }, [props]);

  return (
    <div className="CatalogoDeProductos">
      <div className="Productos">
        {productos.map((producto, index) => (
          <>
            <Link to={`/Catalogo/${idProduct[index]}`} className="Link">
              <div className="itemProducto" key={idProduct[index]}>
                <div className="images">

                  {images[index].map((img, id) => (
                    id === 0 ?
                      <img
                        src={process.env.REACT_APP_UPLOAD_URL + img.attributes.formats.medium.url}
                        className="mainImg" alt="" key={img.attributes.formats.medium.url} />
                      :
                      <img
                        src={process.env.REACT_APP_UPLOAD_URL + img.attributes.formats.medium.url}
                        className="SecondImg" alt="" key={img.attributes.formats.medium.url} />

                  ))}
                  <div className="detallesimg">
                    <h1>DETALLES</h1>
                  </div>

                </div>
                <div className="titulo">
                  <div className="name">
                    {producto.Name}
                  </div>
                  <div className="whatsapp">
                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Evita que el Link intercepte el click
                      window.open(`https://wa.me/528120409989?text=Hola, me interesa el producto ${producto.Name}`, "_blank");
                    }}
                  >
                    <WhatsAppIcon style={{ color: "#25D366", fontSize: "30px", cursor: "pointer" }} />
                  </span>
                  </div>
                  
                  {/*<>
                    {!producto.Existencias ? 
                    <div className="existencias">
                      AGOTADO
                    </div>
                      : 
                      <>
                      {producto.Existencias <= props.LimiteBajaExistencia &&
                        <div className="existencias Baja">
                          A PUNTO DE AGOTARSE
                        </div>
                      }
                    </>
                    }
                    </>
                    */}
                </div>
                {/*
                <div className="preciosContent">
                  {Discount[index] && (
                    <div className="precioLista">
                      {formatter.format(PriceProduct[index] * [1 - Discount[index]])}
                    </div>
                  )}
                  <div className="precioDescuento">
                    {PriceProduct[index] && (
                      <p
                        className={Discount[index] === null ? "price" : "priceT"}>
                        {formatter.format(PriceProduct[index])}
                      </p>
                    )}
                  </div>
                </div>
                */}
              </div>
            </Link>
          </>
        ))}
      </div>
    </div>
  )
}

export default CardProduct