import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Catalogo from "./pages/Catalogo/Catalogo";
import Contacto from "./pages/Contacto/Contacto";
import Inicio from "./pages/Home/Inicio";
import Nosotros from "./pages/Nosotros/Nosotros";
import Ofertas from "./pages/Ofertas/Ofertas";
import DetalleProducto from "./pages/Catalogo/DetalleProducto";
import "./App.css";
import Order from "./components/Cart/Order/Order";
import PayOrder from "./components/Cart/Pay/PayOrder";
import OrdercheckOut from "./components/Cart/CheckOut/checkOut";
import PagoProcesado from "./components/Cart/PagoProcesado/pago_procesado";
import { useLocation } from 'react-router-dom';
import Pedido from "./components/Cart/Pedido/pedido";
import PrivateRoute, { PrivateRouteCartAuth } from "./routes/PrivateRoutes";
import Account from "./pages/UserAccount/Account";
import Login from "./pages/Login/Login";
import Register from "./pages/Login/Register";
import CartAuth from "./components/Cart/CartAuth/CartAuth";
import Delivery from "./components/Cart/Delivery/Delivery";
import Tyc from "./pages/Legal/Tyc";
import Policy from "./pages/Legal/Policy";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Faqs from "./pages/FAQs/Faqs";
import RecuperarPwd from "./pages/Login/RecuperarPwd";
import ConfirmPwd from "./pages/Login/ConfirmPwd";
import Construccion from "./pages/Construccion/Construccion";
import Resumen from "./components/Cart/Resumen/Resumen";
import DirectPay from "./pages/DirectPay/DirectPay";


const Layout = () => {
  const location = useLocation();
  const [showScroll, setShowScroll] = useState(false);

  const path = window.location.pathname;

  useEffect(() => {
    // Lógica para cambiar el título de la página según la ruta actual

    let pageTitle = 'React App'; // Título predeterminado

    switch (path) {
      case '/':
      case '/Inicio':
        pageTitle = 'Linum - Telas finas';
        break;
      case '/Nosotros':
        pageTitle = 'Nosotros - Linum';
        break;
      case '/Catalogo':
      case '/Catalogo/:id':
        pageTitle = 'Catalogo - Linum';
        break;
      case '/Ofertas':
        pageTitle = 'Telas en Oferta - Linum';
        break;
      case '/Contacto':
        pageTitle = 'Contacto - Linum';
        break;
      case '/login':
      case '/Login':
        pageTitle = 'Login - Linum';
        break;
      case '/Account':
        pageTitle = 'Account - Linum';
        break;
      case '/Orden':
      case '/Delivery':
      case '/CheckOut':
      case '/Pay':
      case '/Resumen':
        pageTitle = 'Orden - Linum';
        break;

      // ... Otras rutas
      default:
        break;
    }

    document.title = pageTitle;
  }, [path]);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 100) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 100) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);


  // Utiliza useEffect para detectar un cambio en la ubicación (ruta)
  useEffect(() => {
    window.scrollTo(0, 0); // Haz que la ventana se desplace hacia la parte superior
  }, [location.pathname]);

  //const hideNavbarFooter = location.pathname.includes("/pago_procesado");

  //const isHomePage = location.pathname === '/';
  const isHomePage = location.pathname === '/pago_procesado' || location.pathname === '/DirectPay';

  const showNavbarFooter = !isHomePage;


  return (
    <div className="app">
      {showNavbarFooter && <Navbar />}
      <Outlet />
      {showNavbarFooter && <Footer />}
      {showScroll && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          <ArrowUpwardIcon />
        </div>
      )}
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Inicio />
      },
      {
        path: "/Inicio",
        element: <Inicio />
      },
      {
        path: "/nosotros",
        element: <Nosotros />
      },
      {
        path: "/catalogo",
        element: <Catalogo />
      },
      {
        path: "/catalogo/fam/:idFam",
        element: <Catalogo />
      },
      {
        path: "/catalogo/:id",
        element: <DetalleProducto />
      },
      {
        path: "/ofertas",
        element: <Ofertas />
      },
      {
        path: "/contacto",
        element: <Contacto />
      }
      ,
      {
        path: "/oferta",
        element: <Ofertas />
      }
      ,
      {
        path: "/Orden",
        element: <Order />
      },
      {
        path: "/checkOut",
        element: <OrdercheckOut />
      }
      ,
      {
        path: "/checkOut/:state",
        element: <OrdercheckOut />
      }
      ,
      {
        path: "/Pay",
        element: <PayOrder />
      }
      ,
      {
        path: "/pago_procesado/resp/:resp",
        element: <PagoProcesado />

      },
      {
        path: "/pedido",
        element: <Pedido />

      },
      {
        path: "/Login",
        element: <Login />,
      },
      {
        path: "/Register",
        element: <Register />,
      },
      {
        path: "/CartAuth",
        element: <CartAuth />,
      },
      {
        path: "/tyc",
        element: <Tyc />,
      },
      {
        path: "/policy",
        element: <Policy />,
      },
      {
        path: "/faqs",
        element: <Faqs />,
      },
      {
        path: "/RecuperarPwd",
        element: <RecuperarPwd />,
      },
      {
        path: "/ConfirmPwd",
        element: <ConfirmPwd />,
      },
      {
        path: "/ConfirmPwd/:exp",
        element: <ConfirmPwd />,
      },
      {
        path: "/DirectPay",
        element: <DirectPay />,
      },
      {
        path: "/Delivery",
        element:
          <PrivateRouteCartAuth>
            <Delivery />
          </PrivateRouteCartAuth>,
      },
      {
        path: "/Account",
        element:
          <PrivateRoute>
            <Account />
          </PrivateRoute>,
      },
      {
        path: "/Resumen",
        element: <Resumen />,
      }
    ]
  },

])

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
