import axios from "axios";
import React, { useEffect, useState } from "react";
import "./DirectPay.css";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";

const DirectPay = () => {
    const navigate = useNavigate();
    const [IsloadingSendPay, setIsloadingSendPay] = useState(false);
    const [TCNumber, setTCNumber] = useState('');
    const [TCCvv, setTCCvv] = useState('');
    const [TCMontoPago, setTCMontoPago] = useState('');
    const [Exito, SetExito] = useState(false);
    const [Error, SetError] = useState(false);
    const [Folio, setFolio] = useState('');
    const [ErrorExc, SetErrorExc] = useState(false);
    const secretKey = 'JvsZoZ4Zd83gGROprT8n4VjeANBvEJV7Mox4Un5q4sI='; // La misma clave usada en el servidor
    const currentDate = new Date();

    const handleMontoPago = event => setTCMontoPago(event.target.value);
    const handleTCNumber = event => setTCNumber(event.target.value);
    const handleTCCvv = event => setTCCvv(event.target.value);
    const handleMes = event => setselectedMes(event.target.value);
    const handleAnio = event => setselectedAnio(event.target.value);

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const optionsMes = [
        { value: "", label: "" },
        { value: "01", label: "Enero" },
        { value: "02", label: "Febrero" },
        { value: "03", label: "Marzo" },
        { value: "04", label: "Abril" },
        { value: "05", label: "Mayo" },
        { value: "06", label: "Junio" },
        { value: "07", label: "Julio" },
        { value: "08", label: "Agosto" },
        { value: "09", label: "Septiembre" },
        { value: "10", label: "Octubre" },
        { value: "11", label: "Noviembre" },
        { value: "12", label: "Diciembre" }
    ];

    const [selectedMes, setselectedMes] = useState(
        optionsMes[0].value
    );

    const getYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const options = [{ value: "", label: "" }];
        for (let i = 0; i <= 5; i++) {
            const year = currentYear + i;
            options.push({ value: year.toString().slice(-2), label: year.toString() });
        }
        return options;
    };

    const optionsAnio = getYearOptions();
    const [selectedAnio, setselectedAnio] = useState(optionsAnio[0].value);


    const HandleResetPay = () => {
        SetError(false);
        SetErrorExc(false);
        SetExito(false);
    }

    const HandleResetPaySuccess = () => {  
        SetError(false);
        SetErrorExc(false);
        SetExito(false);
        setTCNumber('');
        setTCCvv('');
        setTCMontoPago('');
        setselectedMes(optionsMes[0].value);
        setselectedAnio(optionsAnio[0].value);
    }

    const generateUUID = () => {
        const generatedFolio = uuidv4();
        return generatedFolio.substring(0, 11);
    };

    const formatTime = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return hours + minutes + seconds;
    };

    const formatDate = (date) => {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return day + month + year;
    };

    const encriptar = (obj) => {
        const objString = JSON.stringify(obj);

        const secret = new window.fernet.Secret(secretKey);
        const token = new window.fernet.Token({ secret });
        const mess_encode = token.encode(objString);

        return mess_encode;
    }

    const HandleSubmit = async (event) => {
        event.preventDefault();

        setIsloadingSendPay(true);

        const DataPago = {
            DataPago: {
                credit_card: TCNumber,
                fecha_expiration: selectedMes + selectedAnio,
                security_code: TCCvv,
                hora_local: formatTime(currentDate),
                fecha_local: formatDate(currentDate),
                folio: generateUUID(),
                credit_card_name: "DirectPay"
            },
            monto: TCMontoPago
        };

        await axios
            .post(process.env.REACT_APP_API_LINUM + '/api/payment_express', { data: encriptar(DataPago) }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response.data.status === "OK") {
                    SetExito(true);
                } else {
                    SetError(true);
                }
                setFolio(response.data.folio);
            })
            .catch((error) => {
                console.error('Error update_user_password:', error);
                SetErrorExc(true);
            });

        setIsloadingSendPay(false);

    }

    const HandleContact = () => {
        navigate("/Contacto");
    };


    return <div>
        <div className="payment-container">
            <div className="payment-header">
                <div className="payment-linum">
                    <img src='/img/linum.jpg' alt='linum' />
                </div>
            </div>

            <h1 className="payment-title">Pago en Línea</h1>

            <div className="payment-logo">
                <img src='/img/VisaMartercard.png' alt='linum' />
            </div>
            {
                Error || ErrorExc ? (
                    <div className="payment-message">
                        <ErrorOutline sx={{ fontSize: 50, color: "red" }} />
                        <p className="payment-message-text">{ErrorExc ? "No pudimos conectarnos con el sistema de pagos. Verifica tu conexión o inténtalo más tarde." :
                            "No pudimos completar tu pago. Revisa los datos de tu tarjeta e inténtalo nuevamente."}</p>
                        {Folio && <p className="payment-message-text-error">Si el problema continúa, envía tu folio a soporte para revisar tu caso. <p className="folio"> {Folio} </p> </p>}
                        <div onClick={HandleResetPay} className="payment-button">Intentar de nuevo</div>
                    </div>
                ) :
                    !Exito ? (
                        IsloadingSendPay ? (
                            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
                                <div className="bg-white p-6 rounded shadow-lg text-center">
                                    <p className="mb-4 text-lg font-semibold text-gray-700">Procesando pago...</p>
                                    <div className="loader"></div>
                                </div>
                            </div>
                        ) :
                            <form onSubmit={HandleSubmit} className="payment-form">
                                <div className="form-group">
                                    <label for="card-number">Número de Tarjeta:</label>
                                    <input type="text" id="card-number"
                                        className="form-input"
                                        value={TCNumber}
                                        onChange={handleTCNumber}
                                        pattern="[0-9]{16}"
                                        title="Ingresa los 16 dígitos de tu tarjeta"
                                        maxLength="16"
                                        inputMode="numeric"
                                        required />
                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label for="cvv">CVV:</label>
                                        <input type="text"
                                            id="cvv"
                                            value={TCCvv}
                                            onChange={handleTCCvv}
                                            pattern="[0-9]{3,4}"
                                            title="Ingresa los 3 o 4 dígitos al reverso de tu tarjeta"
                                            maxLength="4"
                                            inputMode="numeric"
                                            className="form-input" required />
                                    </div>
                                    <div className="form-group">
                                        <label for="amount">Monto a pagar:</label>
                                        <input type="text"
                                            id="amount"
                                            className="form-input"
                                            value={TCMontoPago}
                                            onChange={handleMontoPago}
                                            required />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label for="month">Mes:</label>
                                        <select value={selectedMes}
                                            className="form-input"
                                            onChange={handleMes}
                                            required
                                        >
                                            {optionsMes.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="year">Año:</label>
                                        <select className="form-input" value={selectedAnio}
                                            onChange={handleAnio}
                                            required
                                        >
                                            {optionsAnio.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <button type="submit" className="payment-button">Pagar</button>
                            </form>
                    ) : (
                        <div className="payment-message">
                            <CheckCircleOutline sx={{ fontSize: 60, color: "green" }} />
                            <p className="payment-message-text">Tu pago ha sido procesado exitosamente</p>
                            {Folio && <p className="payment-message-text-success">Folio: {Folio}</p>}
                            <div onClick={HandleResetPaySuccess} className="payment-button">Regresar</div>
                        </div>
                    )
            }
        </div>
    </div>;
};

export default DirectPay;