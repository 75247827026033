import { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export const ComponentOfertas = () => {
  const [Ofertas, setData] = useState({
    images: [],
    currentIndex: 0
  });
  // const [Price, setPrice] = useState([]);
  const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

  useEffect(() => {
    const fetchData = async () => {
      await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?filters[discount][$null]=false&populate=*').then(response => {
        const imgData = response.data.data;
        const filteredDataOferta = imgData.map((Ofertas) => {
          const filteredOfertas = Ofertas.attributes.productImages.data
            ? Ofertas.attributes.productImages.data.filter((Images) => Images !== null)
            : null;

          const filteredDiscount = Ofertas.attributes.discount.data
          return { ...Ofertas, Images: filteredOfertas, discount: filteredDiscount };

        }).filter((Ofertas) => Ofertas.Images !== null);

        // const PriceProduct = filteredDataOferta.map(price =>price.attributes.price_catalogue.data.attributes);
        const PriceProduct = filteredDataOferta.map(price => price.attributes.price);
        // setPrice(PriceProduct);
        setData({
          images: filteredDataOferta,
          currentIndex: 0
        });
      });
    };
    fetchData();
  }, []);

  const nextImages = () => {

    const { images, currentIndex } = Ofertas;

    //console.log(Ofertas);
    const nextIndex = currentIndex + 3 >= images.length ? 0 : currentIndex + 3;
    setData({
      images: images,
      currentIndex: nextIndex
    });
  };

  const prevImages = () => {
    const { images, currentIndex } = Ofertas;
    //console.log(Ofertas);
    const nextIndex = currentIndex - 3 < 0 ? images.length - 3 : currentIndex - 3;
    setData({
      images: images,
      currentIndex: nextIndex
    });
  };

  const getCurrentImages = () => {
    const { images, currentIndex } = Ofertas;
    return images.slice(currentIndex, currentIndex + 3);
  };

  return (
    <div className="contenedorSn2">
      <div className="Ofertastop">
        {getCurrentImages().map((Oferta, index) => (
          <div className=
            {index === 2 ? "item2" : "item1"}
            key={index}>
            <div className=
              {index === 2 ? "imgPrim" : "imgSec"} >
              <img
                className={index === 2 ? "img2" : "img1"}
                value={index}
                src={process.env.REACT_APP_UPLOAD_URL + Oferta.Images[0].attributes.formats.large.url} alt=""></img>
            </div>
            <div className="content-botones">
              <div className="botones">
                {/*             
                              <div className="btnTitulo1">{Oferta.attributes.Name}</div>
                              <div className="btnTitulo3">
                                {formatter.format(Oferta.attributes.price)}
                                </div>
                              <div className="btnTitulo2">
                                {Oferta.attributes.discount && (
                                  <div className="btnTitulo2">
                                    {formatter.format(Oferta.attributes.price * [1-Oferta.attributes.discount])}
                                  </div>
                                )}
                              </div>
                            */}
                <div className="contentBtn">
                  <Link to={"/Catalogo/" + Oferta.id} className='btn'>VER OFERTA <EastIcon className='iconCat' /></Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="MasOfertas">
        <div className="buttons">
          <div className="contentBtn">
            <Link onClick={prevImages}><KeyboardDoubleArrowLeftIcon className='btn' /></Link>
          </div>
          <div className="contentBtn">
            <Link onClick={nextImages}><KeyboardDoubleArrowRightIcon className='btn' /></Link>
          </div>

        </div>
        <div className="botones">
          <div className="contentBtn">
            <Link to="/Catalogo" className='btn'>VER CATALOGO <EastIcon className='iconCat' /></Link>
          </div>
        </div>
      </div>
    </div>
  )
};