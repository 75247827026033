import { React, Fragment, useState } from "react";
import Slider from "react-slick";
import { ImageSlider } from "../../components/Catalogo/CompDetalleProducto.jsx";
import "../Catalogo/DetalleProducto.scss";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const DetalleProducto = () => {
  const [relProds, setRelProds] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: "crosselling",
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 7000,
    Horizontal: true,
    variableHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getRelatedProducts = (relProds) => {
    setRelProds(relProds);
  };

  const { id } = useParams();


  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  const images = relProds.map((image) => (
    <Link key={Math.random()} to={`/Catalogo/${image.id}#Catalogo`}>
      <div>
        <div className="prod_headers">
          <h3>{image.name}</h3>
          {/*
          <h3 className={image.discount !== 0 ? "h3_linethrough" : ""}>
            {formatter.format(image.price)}
          </h3>
          <h3 className={image.discount !== 0 ? "" : "h3_transparent"}>
            {formatter.format(image.price * [1 - image.discount / 100])}
          </h3>
          */}
        </div>
        <img src={process.env.REACT_APP_UPLOAD_URL + image.img} alt="LINUM" />
      </div>
    </Link>
  ));

  return (
    <Fragment>
      <div id="Catalogo" className="wrapperDetalleCatalogo">
        <ImageSlider id={id} onRelatedProds={getRelatedProducts} />

        {relProds.length > 0 &&
          <div className="crosselling">
            <h1>Productos Similares</h1>
            <div className="slideCrosselling">
              <Slider {...settings}>{images}</Slider>
            </div>
          </div>
        }
      </div>
    </Fragment>
  );
};

export default DetalleProducto;